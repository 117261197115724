<template>
  <div class="sellgold">
    
     <van-nav-bar
            title="卖料"
            safe-area-inset-top 
            fixed
            left-arrow
            placeholder
            :border="false"
             @click-left="onClickLeft"
        />
        
    <!-- 实时金价 -->
    <div class="rel">
      <center class="boxgoldprice">
        <h2>{{pricebox}}</h2>
        <div class="pricebox">{{initCity}}</div>
      </center>
      <div class="bottombox">
      <!-- 参数选择 -->
      
      <!-- <div v-if="!loadingtrue"><center class="centerboxloading"><van-loading   size="50"  /> </center></div> -->
      <div class="userselect">
        <!-- <van-cell title="订单类型" is-link :value="initCity" @click="showPopup"  size="large"/> -->
        <van-cell title="订单类型"  :value="initCity"   size="large"/>
        <!-- <div class="title_box">订单类型 </div>
        <div @click="showPopup" :class="issslect? 'citybox drepcolor' : ' citybox'">{{initCity}}</div> -->
        <van-cell title="业务类型" is-link  :value="businessName"  @click="sheetShow = true"  size="large"/>
        <!-- <van-cell title="业务类型"       size="large">
              <template #default>
                  <div style="color:#999999">{{businessName}}</div>
              </template>
        </van-cell> -->
        
        <div class="title_box ">
            <div class="flex-between weightbox">
                <span>重量</span>
                <el-input v-model="weight" placeholder="0" type="tel" @input="handleInput2" maxlength="6" :class="isinput?'yellowfont':''" ></el-input>
                <div class="unit">克</div>
            </div>
        </div>      
        <div class="title_box ">
            <div class="flex-between weightbox">
                <span>定金 <i class="dingJIn">(可用定金 <span class="dingJinData">{{freeEarnestMoney}}</span> )</i> </span>
                <div class="countMumber"  :style="{color:isinput?'#242424':'#D1D1D1'}"> <span class="zuiDi">最低定金</span> {{countMumber}}</div>
                <div class="unit">元</div>
            </div>
        </div>
          <!-- <div class="title_box">
            <div class="textTip">温馨提示: {{sellTips}}</div>
          </div> -->
        </div>
      </div>
      <div class="btnbox" :style="{background: Number(weight) ?'#ff2d2e':'#f7cdcd'}" @click="postData('showpop')">立即卖料</div>

      <div class="tipBox">
        <div>
          温馨提示
          <div class="leftLine"></div>
          <div class="rightLine"></div>
          </div>
          <div v-html="sellTips"></div>
      </div>
      <div>
        
        <van-popup v-model="show" position="bottom">
          <van-area :area-list="areaList"   @confirm="confirmF" cancel-button-text=" "/>
        </van-popup>
      </div>

      <van-action-sheet
          v-model="sheetShow"
          :actions="businessType"
          cancel-text="取消"
          close-on-click-action
           @select="onSelect"
        />
      <!-- 卖金须知 -->
      <!-- <van-dialog v-model="tipsell">
            <div class="title">卖金须知</div>
            <div class="centerbox_dialog _leftbox">
              <p>1、我司仅支持实物贵金属回收，黄金各品类定价选择黄金9999即可，粤恒通收到货物后将根据黄金9999的折足克重为您结算。</p>
              <p>2、您的定价审核通过后请在72小时内将货物寄出，只定价不发货属于违约行为，我方有权追究法律责任，请谨慎定价。</p>
            </div>
            <div class="gocer" @click="tipsell=false">确定</div>
        </van-dialog> -->
        <!-- 定价确认 -->
        <van-dialog v-model="showorder">
          <div class="title">订单确认</div>
          <div class="confirmCenterbox_dialog">
            
            <div class="flex orderInfo">  <div class="orderCol">订单类型</div>  <div>{{initCity}}实物</div> </div>
            <div class="flex orderInfo">   <div class="orderCol">订单类型</div>  <div>{{businessName}}</div></div>
            <div class="flex orderInfo">  <div class="orderCol">重量</div>  <div>{{weight}}g</div></div> 
            <div class="flex orderInfo">   <div class="orderCol">单价</div>  <div> <span style="color:#FF4B71">{{pricebox}}</span> 元/克</div></div>
            <div class="flex orderInfo">   <div class="orderCol">押金</div>  <div>{{countMumber}}元</div></div>
          </div>
          <!-- <div class="text">温馨提示:{{sellTips}}.</div> -->
          <div class="autograph">
                  <div class="autographText">手写签名</div>
                  <div class="autographBox">
                    <img  v-if="img" :src="img" alt="">
                  </div>
          </div>
          <div class="flex popbtn">
            <div class="gocer closebtn" @click="closepop">取消</div>
            <div class="gocer confirmbtn" @click="postData('postDataf')">确定</div>
          </div>
        <div class="Loading"><van-loading  v-if="Loading"  size="30"  /></div>

        </van-dialog>

        <van-dialog v-model="ceringModel" class="ceringModel">
            <div class="title">提示</div>
            <div class="centerbox_dialog">{{stopTrading}}</div>
            <div class="confirm" @click="ceringModel=false">我知道了</div>
        </van-dialog>          
      </div>
  </div>
    
</template>
<script>
import Stomp from 'stompjs'
import 'vant/lib/popup/style';
import 'vant/lib/area/style';
import AreaList from './select_type';
import * as PriceLineChart from '@/utils/api'
import autograph from './components/autograph.vue'
// import ReconnectingWebSocket from 'reconnectingwebsocket'
import headbox from'@/components/head.vue'
import { watch } from 'vue';

export default {
  inject: ["reload"],
  data(){
    return{
      tipcenter: false,
      showorder: false,
      tipsell: false,
      secondlastData: [],
      // 首次加载的数据
      initDatalist: [],
      firstloadData: true,
      ceringModel:false,
      // 防止重复提交
      isclick: false,
      // 提交的时间
      postTime: '',
      // 选择的产品key
      productkey: 'huangjin9999',
      // 是否输入状态
      isinput: false,
      // 预估金额
      countMumber:'0',
      loadingtrue: false,
      // 可用定金
      freeEarnestMoney:'',
      // 所有商品数组
      allpro:[],
      pricebox: '--.--',
      issslect: true,
      areaList: AreaList,
      sheetShow:false,
      show: false,
      initCity: '黄金',
      // 业务类型
      businessType:[
        {name:'近期交货',id:'0'},
        {name:'延期交货',id:'1'}],
      businessName:'延期交货',
      businessTypeID:'1',
      keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      // 卖出克重
      weight: '',
      wsUrl : localStorage.getItem('ws'),
      trade_: false,
      client: {},
      key:'',
      img:"",
      depositProportion:{},
      InsufficientDeposit:false,
      Loading:false,
      tips:{
        sellRecentTips:'',
        sellDelayTips:''
      },
      sellTips:'',  //卖料说明
      userInfo:{}, // 用户信息
      stopTrading:'',//停盘说明
    }
  },
  // activated(){
  //   this.img = this.$route.query.img

  // },
  // deactivated(){
  //   this.img = ''

  // },
  mounted() {

    PriceLineChart.mortgageApi('UNDELIVERED',0).then(res=>{
      // if(res.content.length>=1){
      //   this.tipsell = false
      // }else{
      //     const isshowpop = localStorage.getItem('first_page_box')
      //     if(isshowpop){
      //         this.tipsell = false
      //     }else{
      //       localStorage.setItem('first_page_box',true)
      //       this.tipsell = true
      //     }
      // }
    })
    this.pricebox = this.$route.query.price
        // 查询可用定金接口
    PriceLineChart.getAvailableMoney().then(res=>{
      this.freeEarnestMoney = res.freeEarnestMoney
    });
         
      let res = JSON.parse(localStorage.getItem('userinfo')) 
      this.img = res.sign
       this.depositProportion = res.depositDetail.curSell
    this.connect()
  },
  watch:{
    allpro:function(){
      if(this.initCity != '请选择类别'){
        this.updatearr(this.initCity)
      }
    },
    weight(newval,oldVal){
        if (!newval) {
            this.countMumber = 0
            this.isinput = false
        }
    },
    countMumber(newval,oldVal){
      if (newval > this.freeEarnestMoney) {
        this.InsufficientDeposit = true
        this.weight =0
           this.$toast('定金不足!请充值')
      }else{
        this.InsufficientDeposit = false

      }
    }
  },
  components:{
    headbox,
    autograph 
  },
  destroyed() {
    clearTimeout(this.setInterval)
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    })
  },
async  created(){
    let res = await PriceLineChart.getDefaultExplain({title:'sellRecentTips'})
      this.tips.sellRecentTips = res.content
    let res1 = await  PriceLineChart.getDefaultExplain({title:'sellDelayTips'})
      this.tips.sellDelayTips = res1.content
      this.sellTips = res1.content
    let stopTrading = await  PriceLineChart.getDefaultExplain({title:'stopTradingDescription'})
      this.stopTrading = stopTrading.content
      this.showorder = false
  },
  methods: {
    onClickLeft(){
      this.$router.push({
        name:'pricing'
      })
      this.weight = '' 
    },
    // 业务类型选择
    onSelect(item){
      console.log(item);
      this.businessName = item.name
      this.businessTypeID = item.id
       if ( item.id == '0') {
          //近期交货
          this.sellTips = this.tips.sellRecentTips
       }else{
         //延期交货
          this.sellTips = this.tips.sellDelayTips
       }
    },
    // // 手写签名按钮事件
    // linkAutograph(){
    //   this.$router.push({
    //     name:'autograph',
    //     query:{path:'sellgold'}
    //   })
    // },
    showTip(){
      if(this.tipcenter){
        return false
      }
      this.tipcenter = true
      setTimeout(()=>{
        this.tipcenter = false
      },3000)
    },
    closepop(){
      this.showorder=false
      this.isclick=false
    },
    postData(type) {
      if(type == 'postDataf'){
          const postData = {
            "addr": '',
            "lat": 0,
            "lng": 0,
            "order": [
              {
                "key": this.productkey,
                "weight": this.weight
              }
            ],
            "time": this.postTime,
            "businessType": this.businessTypeID
          }   
          PriceLineChart.SellOrder(postData).then(res=>{
                this.weight = ''
                this.countMumber = 0
                this.showorder = false
                this.isinput = false
                this.$router.push({name:'successpage',query:{type:'sell'}})
          }).catch(err=>{

          }) 
      }else{
          if(!this.trade_){
            this.ceringModel = true
            return false
          }
          // if(this.isclick){
          //   return false
          // }
          this.isclick = true
          if(! Number(this.weight)){
            return this.$toast('克重不能为空'),!1
          }
          if (this.InsufficientDeposit) {
              return this.$toast('定金不足!请充值'),!1
          }
           if(this.businessTypeID == '1' && this.weight < 1000){
                  return this.$toast('最低1000起步'),!1
          }else if(this.businessTypeID == '1' && this.weight%1000 != 0 && this.weight != 0){
                  return this.$toast('仅支持1000的整数倍订单'),!1
          }
          this.showorder = true
          // this.isclick = false
      }
    },
    countmunber(){
      
    },
    handleInput2(e){
      if(this.weight == 0){
        this.weight = ''
        return !1
      }else{
        this.isclick = false
        this.isinput = true
        this.weight = (this.weight.match(/^\d*(\.?\d{0,2})/g)[0]) || null
        this.countMumber = ((+this.depositProportion.policy)*(+this.weight))

        // debugger
      }
    },
    unzip(b64Data){
        let strData   = atob(b64Data);
        const charData  = strData.split('').map(function(x){return x.charCodeAt(0);});
        const binData   = new Uint8Array(charData);
        const data    = pako.inflate(binData);
        strData   = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = '/price/all'
      this.client.subscribe(topic, this.responseCallback, this.onFailed) 
    },
    connect () {
            this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
        console.log('client2',this.client);
      // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
        'login': 'username',
        'passcode': 'password',
      }

      this.client.connect( headers, this.onConnected, this.onFailed)
      this.client.debug = null

    },
    onFailed (frame) {
      console.log('错误: ' + frame)
      // console.log('this.client',this.client);
      
      if (!this.client.connected) {
        setTimeout(() => {
          // console.log('11');
          this.connect();
        }, 1000);
      }
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString
      return toString.call(val) === '[object Object]'
    },
    deepMerge(...objs) {
      const than = this
      const result = Object.create(null)
      objs.forEach(obj => {
        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key]
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val)
              } else {
                result[key] = than.deepMerge(val)
              }
            } else {
                //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val]
              } else {
                result[key] = val
              }
            }
          })
        }
      })
      return result
    },
    responseCallback (frame) {
      this.allpro = []
      const resbody = eval('['+this.unzip(frame.body)+']')[0]
      const parse_res = JSON.parse(JSON.stringify(resbody));
      this.trade_ = resbody.trade;
      this.postTime = resbody.time
      if(this.firstloadData){
        this.initDatalist = resbody
        this.firstloadData = false
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }else{
        this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        for(const i in this.initDatalist){
          if(this.initDatalist[i]['prices'] != undefined){
            this.allpro.push(this.initDatalist[i]['prices'])
          }
        }
      }
      // this.loadingtrue = true
    },
    // 价格变动更新视图
    pricenew(name){
      this.allpro.find((x)=>{
        if(x[name]){
          this.pricebox = x[name]['huigou']
        }
      })
    },
    // 更新数组
    updatearr(name){
      if(name == '黄金'){
        this.pricenew('huangjin9999')
        this.productkey = 'huangjin9999'
      }else if(name == '黄金金条999'){
        this.pricenew('huangjin999jt')
        this.productkey = 'huangjin999jt'
      }else if(name == '黄金首饰999'){
         this.pricenew('huangjin999ss')
         this.productkey = 'huangjin999ss'
      }else if(name == '黄金18K 750'){
         this.pricenew('huangjin750')
         this.productkey = 'huangjin750'
      }else if(name == '黄金(无法确定)'){
         this.pricenew('huangjin9999')
         this.productkey = 'huangjin9999'
      }else if(name == '铂金'){
         this.pricenew('bojin9996')
         this.productkey = 'bojin9996'
      }else if(name == '铂金首饰999'){
         this.pricenew('bojin999')
         this.productkey = 'bojin999'
      }else if(name == '铂金首饰990'){
         this.pricenew('bojin900')
         this.productkey = 'bojin900'
      }else if(name == '铂金首饰950'){
         this.pricenew('bojin950')
         this.productkey = 'bojin950'
      }else if(name == '铂金(无法确定)'){
         this.pricenew('bojin9996')
         this.productkey = 'bojin9996'
      }else if(name == '钯金'){
         this.pricenew('bajin9996')
         this.productkey = 'bajin9996'
      }else if(name == '钯金首饰999'){
         this.pricenew('bajin999')
         this.productkey = 'bajin999'
         this.pricenew('bajin990')
         this.productkey = 'bajin990'
      }else if(name == '钯金首饰950'){
         this.pricenew('bajin950')
         this.productkey = 'bajin950'
         this.pricenew('bajin9996')
         this.productkey = 'bajin9996'
      }else if(name == '白银'){
         this.pricenew('baiyin9999')
         this.productkey = 'baiyin9999'
      }else if(name == '白银首饰999'){
         this.pricenew('baiyin999')
         this.productkey = 'baiyin999'
      }else if(name == '白银首饰990'){
         this.pricenew('baiyin990')
         this.productkey = 'baiyin990'
      }else if(name == '白银首饰925'){
         this.pricenew('baiyin925')
         this.productkey = 'baiyin925'
      }else if(name == '白银(无法确定)'){
         this.pricenew('baiyin9999')
         this.productkey = 'baiyin9999'
      }
    },
    confirmF(picker, value, index){
      this.show = false;
      this.issslect = true
      this.initCity =   picker[0].name
      console.log('initCity',this.initCity);
      
      this.weight = ''
      this.countMumber = '0'
      this.updatearr(picker[0].name)
    },
    showPopup() {
      this.show = true;
    },
  },
}
</script>
<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        background-color: #fff;
        .van-nav-bar__title{
            font-size: 36px;
            color: #242424;
        }
        .van-icon{
            font-size: 40px;
            color: #242424;
            
        }
        }
     }
}
::v-deep{
    .van-cell:after{
       border-bottom: 1px solid #E0E0E0 !important;
    }
  .van-cell{
    padding: 30px  20px;

    .van-cell__title{
      font-size: 36px;
      color: #666666;
      line-height: 50px;
    }
    .van-cell__value{
      font-size: 36px;
      color: #242424;
      line-height: 50px;
    }
    .van-icon{
      font-size: 36px;
      color: #9d9d9d;
      line-height: 50px;
    }
  }
  .van-picker__columns{
    display: block;
  }
  .van-popup.van-popup--round.van-popup--bottom.van-popup--safe-area-inset-bottom.van-action-sheet{
    height: 300px;
  }
  .van-action-sheet__item{
    line-height: 80px;
    font-size: 32px;
  }
  .van-action-sheet__cancel, .van-action-sheet__item{
    font-size: 32px;
  }
}
.dingJIn{
   font-style: normal;
   font-size: 24px;
   .dingJinData{
   font-size: 24px;
   color: red;
   }
}
.tipboxback{
  color:#f2aa2e;
  background:rgba(0,0,0,.8);
  border-radius: 8px;
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top:-25px;
  &:after{
    content: '';
    width: 0;
    height: 0;
    left: 125px;
    bottom: -12px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid rgba(0,0,0,.8)
  }
}
.tipboxcarid{
  font-style: normal;
  text-align: center;
  border-radius: 8px;
  border:1px solid #f2aa2e;
  width: 30px;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  color:#f2aa2e;
  border-radius: 50%;
  display: inline-block;
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 210px;
    text-align: center;
    border-radius: 50px;
  }
  
}
.gocer{
  margin: 0 auto;
  width: 280px;
  height: 72px;
  font-size: 28px;
  line-height: 72px;
  margin-top: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #FF2D2E;
}
.centerbox_dialog{
  text-align: left;
  padding: 0 50px;
  color: #666666;
  // padding: 0 10px;
  font-size: 28px;
}
.flex{
  display: flex;
  justify-content: space-between;
  }
.confirmCenterbox_dialog{
  text-align: left;
  padding-bottom: 30px;
  font-size: 28px;
}
.text{
  font-size: 20px;
  color: #666666;
  line-height: 38px;
  margin: 30px 0;
}
::v-deep .van-dialog{
    width: 90%;
    padding: 0 40px 30px;
    .title{
        color: #242424;

    }
  }
::v-deep .van-button{
    display: none
  }
.loadigboxdetals{
  height: 140px;
  .van-loading{
    padding-top: 200px;
  }
}
.Loading{
position: absolute;
top: 34%;
left: 50%;
margin-left: -40px;
}
.title{
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.userselect{
  background: #fff;
  padding: 10px 0 40px; 
  border-radius: 4px 4px 4px 4px;
  // background: red;
}
.countMumber{
  color:#D1D1D1;
  margin-right: 50px;
  margin-top: 12px;
  font-size: 36px;
}
::v-deep .van-loading__spinner .van-loading__spinner--circular{
  font-size: 80px;
}
.centerboxloading{
  font-size: 80px;
}
.bottombox{
  padding: 0 30px 20px;
}
.citybox{
  color:#CCCCCC;
  font-size: 40px;
  margin: 0 30px;
}
.drepcolor{
  color:#292929;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
::v-deep .van-picker__confirm{
  font-size: 32px;
}
::v-deep .van-picker__columns .van-picker-column:nth-child(2){
  display: none
}
::v-deep .van-picker-column__item{
  font-size: 30px;
}
::v-deep .el-input__inner{
  border:0;
  height: auto;
  font-size: 40px;
  padding: 0;
  text-align: right;

}
::v-deep .el-input{
  width: 30%;
  margin-right: 50px;
  margin-top: 4px;
}
::v-deep .el-input__inner::placeholder {
  color:#CCCCCC
}
  .weightbox{
  border-bottom: 1px solid #E0E0E0;
  padding: 4px 10px 22px;
  span{
    padding: 10px 0px;
  }
  .zuiDi{
    font-size: 22px;
    color: #D1D1D1;
  }
  }
  .unit{
    position: absolute;
    top: 30%;
    right: 28px;
    color: #242424;
    font-size: 40px;
  }
  .title_box{
  position: relative;
  margin: 0 !important;
  padding: 28px 18px 0 !important;
  span{
    font-size: 36px;
    color: #666666;
  }
.textTip{
  font-size: 20px;
  color: #666666;
  line-height: 34px;
}
  }
  .moneybox{
  padding:  0 30px;
  font-size: 40px;
  color:#CCCCCC;
  b{
    font-weight: normal;
  }
  }
  .title_box{
  font-size: 28px;
  color:#292929;
  margin: 0 30px;
  color:#292929;
  padding: 30px 0 30px;
  }
  .btnbox{
  width: 690px;
  background: #ff2d2e;
  color:#fff;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  margin-top: 30px;
  line-height: 88px;
  border-radius: 50px;
  }
  .tipBox{
    padding: 60px 30px 70px;
    color:#999999;
    font-size: 24px;
    :nth-child(1){
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      .leftLine{
        width: 80px;
        border: 1px solid #999999;
        position: absolute;
        top: 16px;
        left: 30%;
      }
      .rightLine{
        width: 80px;
        border: 1px solid #999999;
        position: absolute;
        top: 16px;
        right: 30%;
      }
    }
    div{
      line-height: 38px;
      margin-bottom: 10px;
    }
  }
  .yellowbox{
    text-align: center;
    background: #FEAE14;
    width: 750px;
    height: 200px;
    border-radius: 0 0 10% 10%;
    
  }
  .yellowfont{
    color:#FEAE14!important
  }
  .yellowfont ::v-deep .el-input__inner{
    color:#FEAE14!important
  }
  .rel{
    background: #f5f5f5;
  }
  .boxgoldprice{
    // height: 200px;
    overflow: hidden;
    width: 700px;
    margin: 30px auto;
    background: url('~@/assets/images/pic_sell_price@2x.png') no-repeat;
    background-size: 100% 100%;
    h2{
      color:#FF4B71;
      font-weight: 600;
      font-size: 44px;
      margin-top: 34px;
    }
    .pricebox{
      margin-top: 0px;
      font-size: 30px;
      color:#242424;
      margin: 21px 0 30px;
    }
  }
  .closebtn{
    background-color: #D1D1D1;
  }
  .confirmbtn{
    background-color:#FF2D2E ;
  }
  .confirm{
    text-align: center;
    height: 40px;
    color: #FF2D2E;
    margin-top: 40px;
    font-size: 28px;
    line-height: 40px;
  }
  ._leftbox{
  padding-left: 10px;
  }
  .sellgold{
       background-color: #f5f5f5;
       padding-bottom: 40px;
       height: 100%;
  }
  .orderInfo{
    padding: 12px 0;
    .orderCol{
      color: #999999;
    }
  }
  .autograph{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    // box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;
    .autographText{
        width: 100%;
        height: 56px;
        line-height: 56px;
        color: #fff;
        background-color: #5473FF;
        font-size: 24px;
    }
    .autographBox{
        height: 200px;
        border: 1px solid #E9E9E9;
        img{
          width: 200px;
          height: 100%;
          transform:rotate(270deg);
        }
    }
  }
  ._leftbox{
  padding: 0 !important;
  }
  .ceringModel{
    width: 74%;
    .centerbox_dialog{
      padding: 0 0 30px !important;
      border-bottom: 1px solid #E9E9E9;
    }
    .confirm{
      margin: 30px 0  0;
    }
  }
</style>